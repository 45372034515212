import React from 'react';
const SvgLogo = () => {
    return (
        <div id="svg_logo_div">
            <div className="img_area fade-in-1s">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 800 350" width="800" height="350">
<g className="svg-rumble">
	<path id="Ja_00000010998764594648771780000007208793959326303392_" className="st0 svg-iam" d="M217.7,183.9h-16.5v14.1c0,0.6-0.3,0.9-0.9,0.9
	H186c-0.4,0-0.6-0.2-0.6-0.5v-2.5c0-0.4,0.2-0.6,0.6-0.6h11.6v-14c0-0.6,0.2-0.9,0.9-0.9h16.5v-10.9h-30.8c-0.4,0-0.6-0.2-0.6-0.5
	v-2.5c0-0.4,0.2-0.6,0.5-0.6h33.6c0.6,0,0.9,0.3,0.9,0.9v16.2C218.6,183.6,218.3,183.9,217.7,183.9z"/>
<path id="Ji_00000132071174714773634170000016256573159903033744_" className="st0 svg-iam2" d="M256.5,176.4h-13.3v22.4c0,0.4-0.2,0.6-0.6,0.6
	h-2.6c-0.4,0-0.6-0.2-0.6-0.6v-22.4h-16.8c-0.4,0-0.5-0.2-0.5-0.6v-2.5c0-0.4,0.1-0.6,0.5-0.6h30.9v-8.3c0-0.4,0.2-0.6,0.6-0.6h2.7
	c0.3,0,0.6,0.2,0.6,0.6v11C257.5,176.2,257.2,176.4,256.5,176.4z"/>
<path id="Ja_00000101085145912556629320000007160698987783189914_" className="st0 svg-iam3" d="M295.6,183.9h-16.5v14.1c0,0.6-0.3,0.9-0.9,0.9
	h-14.3c-0.4,0-0.6-0.2-0.6-0.5v-2.5c0-0.4,0.2-0.6,0.6-0.6h11.6v-14c0-0.6,0.2-0.9,0.9-0.9h16.5v-10.9H262c-0.4,0-0.6-0.2-0.6-0.5
	v-2.5c0-0.4,0.2-0.6,0.5-0.6h33.6c0.6,0,0.9,0.3,0.9,0.9v16.2C296.5,183.6,296.2,183.9,295.6,183.9z"/>
<path id="Jmu" className="st0 svg-iam4" d="M332.7,198.8H301c-0.6,0-0.9-0.3-0.9-0.9V166c0-0.6,0.4-1,1-1h8.3c0.4,0,0.6,0.2,0.6,0.6v2.5
	c0,0.4-0.2,0.6-0.6,0.6h-5.6v26.5H330v-14.9c0-0.4,0.2-0.6,0.6-0.6h2.4c0.4,0,0.6,0.2,0.6,0.6v17.6
	C333.6,198.5,333.3,198.8,332.7,198.8z"/>
<path id="Jdot_00000081634079654732489940000002512906967625202879_" d="M344.2,183.7h-3.5c-0.6,0-0.9-0.3-0.9-0.9v-3.3
	c0-0.6,0.3-0.9,0.9-0.9h3.5c0.6,0,0.9,0.4,0.9,1v3.2C345.1,183.4,344.8,183.7,344.2,183.7z" className="svg-black1"/>
<path id="Ja_00000133511922670483041620000000261536103187980193_" d="M377.5,183.9H361v14.1c0,0.6-0.3,0.9-0.9,0.9h-14.3
	c-0.4,0-0.6-0.2-0.6-0.5v-2.5c0-0.4,0.2-0.6,0.6-0.6h11.6v-14c0-0.6,0.2-0.9,0.9-0.9h16.5v-10.9H344c-0.4,0-0.6-0.2-0.6-0.5v-2.5
	c0-0.4,0.2-0.6,0.5-0.6h33.6c0.6,0,0.9,0.3,0.9,0.9v16.2C378.4,183.6,378.1,183.9,377.5,183.9z" className="svg-black1"/>
<path id="Jdot" d="M387.2,183.7h-3.5c-0.6,0-0.9-0.3-0.9-0.9v-3.3c0-0.6,0.3-0.9,0.9-0.9h3.5c0.6,0,0.9,0.4,0.9,1v3.2
	C388.1,183.4,387.8,183.7,387.2,183.7z" className="svg-black1"/>
<path id="Jco" d="M419,198.8h-31.9c-0.4,0-0.6-0.2-0.6-0.6v-2.4c0-0.4,0.2-0.6,0.6-0.6h29.1v-25.4h-29.1c-0.4,0-0.6-0.2-0.6-0.5
	v-2.4c0-0.4,0.2-0.6,0.5-0.6h32c0.6,0,0.9,0.4,0.9,1v30.8C420,198.6,419.6,198.8,419,198.8z" className="svg-black2"/>
<path id="Jme" className="st0 svg-iam" d="M459.9,193.8c-0.1,0-0.2,0-0.3-0.1l-16.3-9.5l-7.9,14.1c-0.2,0.4-0.4,0.6-1,0.6h-8.8
	c-0.4,0-0.6-0.2-0.6-0.6v-2.5c0-0.4,0.2-0.6,0.5-0.6h7.2l7.3-13l-14.7-8.7c-0.3-0.1-0.4-0.3-0.4-0.6v-3.3c0-0.2,0.1-0.4,0.2-0.4
	c0.1,0,0.2,0,0.3,0.1l16.3,9.6l7.5-13.3c0.2-0.3,0.4-0.4,0.8-0.4h9.2c0.4,0,0.6,0.2,0.6,0.6v2.5c0,0.4-0.2,0.5-0.6,0.5h-7.3
	l-6.8,12.1l14.8,8.6c0.2,0.2,0.3,0.3,0.3,0.6v3.2C460.1,193.7,460,193.8,459.9,193.8z"/>
<path id="Jde" className="st0 svg-iam2" d="M496.8,181.4h-7.6v16.4c0,0.7-0.4,1-1,1h-24.7c-0.4,0-0.6-0.2-0.6-0.5v-2.5c0-0.4,0.2-0.6,0.6-0.6
	h21.9v-13.8h-23.2c-0.4,0-0.6-0.2-0.6-0.6v-2.4c0-0.4,0.2-0.6,0.6-0.6h34.6c0.4,0,0.6,0.2,0.6,0.6v2.4
	C497.4,181.2,497.2,181.4,496.8,181.4z M488.9,169.8h-26.4c-0.4,0-0.6-0.2-0.6-0.6v-2.5c0-0.4,0.2-0.6,0.6-0.6h26.4
	c0.4,0,0.6,0.2,0.6,0.6v2.5C489.6,169.6,489.3,169.8,488.9,169.8z M498.4,167.3H496c-0.3,0-0.4,0-0.6-0.2v2.6c0,0.3-0.2,0.6-0.6,0.6
	h-2.4c-0.4,0-0.6-0.2-0.6-0.6v-2.8c0-0.4,0.2-0.6,0.6-0.6h2.4c0.3,0,0.4,0.1,0.6,0.3V164c0-0.4,0.2-0.6,0.6-0.6h2.4
	c0.4,0,0.6,0.3,0.6,0.6v2.8C499,167.2,498.8,167.3,498.4,167.3z"/>
<path id="Ji" className="st0 svg-iam3" d="M528.6,180.9h-10.6v17.9c0,0.4-0.2,0.5-0.5,0.5H515c-0.3,0-0.5-0.1-0.5-0.5v-17.9h-13.4
	c-0.4,0-0.5-0.2-0.5-0.5v-2.4c0-0.4,0.1-0.5,0.4-0.5h24.9v-6.4c0-0.4,0.2-0.6,0.6-0.6h2.5c0.4,0,0.5,0.2,0.5,0.6v8.9
	C529.5,180.7,529.2,180.9,528.6,180.9z"/>
<path id="Ja" className="st0 svg-iam4" d="M564.9,183.9h-16.5v14.1c0,0.6-0.3,0.9-0.9,0.9h-14.3c-0.4,0-0.6-0.2-0.6-0.5v-2.5
	c0-0.4,0.2-0.6,0.6-0.6h11.6v-14c0-0.6,0.2-0.9,0.9-0.9h16.5v-10.9h-30.8c-0.4,0-0.6-0.2-0.6-0.5v-2.5c0-0.4,0.2-0.6,0.5-0.6h33.6
	c0.6,0,0.9,0.3,0.9,0.9v16.2C565.8,183.6,565.5,183.9,564.9,183.9z"/>
<path id="Jn" d="M585.7,173.1h-2.5c-0.3,0-0.6-0.2-0.6-0.5v-2.8h-13c-0.3,0-0.6-0.2-0.6-0.6v-2.6c0-0.4,0.2-0.6,0.5-0.6h15.9
	c0.6,0,0.9,0.3,0.9,0.9v5.4C586.3,173,586.2,173.1,585.7,173.1z M601.4,198.8H570c-0.4,0-0.6-0.2-0.6-0.6v-2.5
	c0-0.4,0.2-0.6,0.6-0.6h28.7v-29.4c0-0.4,0.2-0.6,0.6-0.6h2.6c0.4,0,0.5,0.2,0.5,0.6v32C602.4,198.6,602.1,198.8,601.4,198.8z" className="svg-black1"/>
	<rect id="i" x="18.7" y="53.3" className="st0 svg-iam" width="17.3" height="87.1"></rect>
	<path id="a_00000057862538907267718260000005190833805937999001_" className="st0 svg-iam2" d="M49.6,140.3h18.6l2.7-7.2h26.6l2.7,7.2h18.6
		L84.1,50.6L49.6,140.3z M75.5,120.6l8.6-22.5l8.5,22.5H75.5z"/>
	<polygon id="m" className="st0 svg-iam3" points="154.1,88.5 122,50.9 122,140.3 139.3,140.3 139.3,99.8 154.1,117.1 168.8,99.8 168.8,140.3
		186.1,140.3 186.1,50.9 	"></polygon>
<path id="a" d="M199.7,140.3l34.6-89.7l34.4,89.7h-18.6l-2.7-7.2h-26.6l-2.7,7.2H199.7z M225.6,120.6h17.2l-8.5-22.5L225.6,120.6z" className="svg-black1"/>
<path id="c" d="M274,96.9c0-25,20.3-45.4,45.4-45.4c12.5,0,23.8,5.1,32,13.2l-12.2,12.2c-5.1-5.1-12.1-8.3-19.8-8.3
	c-15.6,0-28.2,12.6-28.2,28.2c0,15.5,12.6,28.1,28.2,28.1c7.8,0,14.7-3.2,19.8-8.3l12.2,12.2c-8.3,8.1-19.6,13.2-32,13.2
	C294.4,142.1,274,121.9,274,96.9z" className="svg-black2"/>
<path id="o" d="M344.5,96.9c0-25,20.3-45.4,45.4-45.4c25,0,45.3,20.3,45.3,45.4c0,25-20.2,45.3-45.3,45.3
	C364.8,142.1,344.5,121.9,344.5,96.9z M417.7,96.9c0-15.4-12.5-27.8-27.8-27.8s-28,12.5-28,27.8s12.6,27.8,28,27.8
	S417.7,112.2,417.7,96.9z" className="svg-black3"/>
<polygon id="m_00000177466283206656373380000018139983614951971725_" className="st0 svg-iam" points="439.5,50.9 439.5,140.3 456.8,140.3
		456.8,99.8 471.6,117.1 486.3,99.8 486.3,140.3 503.6,140.3 503.6,50.9 471.6,88.5 	"></polygon>
	<polygon id="e" className="st0 svg-iam2" points="513.8,140.3 560.2,140.3 560.2,123.1 531,123.1 531,105.1 560.2,105.1 560.2,88 531,88
		531,70.6 560.2,70.6 560.2,53.3 513.8,53.3"></polygon>
	<path id="d" className="st0 svg-iam3" d="M588.5,53.3h-22v87.1h22c24,0,43.6-19.4,43.6-43.5S612.5,53.3,588.5,53.3z M588.5,123.1h-4.7V70.6h4.7
		c14.6,0,26.3,11.7,26.3,26.3C614.8,111.4,603.1,123.1,588.5,123.1z"/>
	<rect id="i_00000073703999265859063890000012427779906940103359_" x="639.1" y="53.3" className="st0 svg-iam4" width="17.3" height="87.1"></rect>
	<path id="a_00000096756018773014313180000004180800933211652484_" className="st0 svg-iam5" d="M694.4,50.6l-34.6,89.7h18.6l2.7-7.2h26.6
		l2.7,7.2h18.6L694.4,50.6z M685.7,120.6l8.6-22.5l8.5,22.5H685.7z"/>
<path id="n" d="M781.3,142.8l-36.9-43v40.5h-17.3V50.9L764,93.9V53.3h17.3V142.8z" className="svg-black4"/>
</g>
</svg>
    </div>
</div>
    )
}

export default SvgLogo;
