import clsx from 'clsx'
import SvgLogo from './SvgLogo'
import { useEffect, useState } from 'react'

const Preloader =() => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 2000);
  }, [])

  return <div id="preloader" className={clsx(hide && 'slide-in')}>
      <div className="logos">
        <img className="muramoto_long" src="//iamacomedian.jp/images/muramoto_long.png" title="村本大輔、ステージ" alt="村本大輔" width="100px" height="auto"/>
          <SvgLogo />
      </div>
  </div>
}

export default Preloader;
